<template>
	<main>
		<HeaderTab :title="$t('global.titre_contact_liste')" :return_action="'navigationBack'"/>
		<div id="content">
			<div class="container-fluid">
				<div class="row">
					<div class="col-12">
						<div class="box">
							<CustomTable
								ref="table"
								id_table="contacts_list"
								:busy.sync="table_busy"
								primaryKey="contact_id"
								:hide_if_empty="true"
								:items="filtredContacts"
								:rawColumns="rawColumnNames"
								:externSlotColumns="extern_slot_columns"
								:hrefsRoutes="config_table_hrefs"
							>
								<template v-slot:[`custom-slot-cell(contact_operateur)`]="{ data }">
									<font-awesome-icon class="text-success" v-if="data.contact_operateur" :icon="['fal', 'check-circle']" />
									<font-awesome-icon class="text-danger" v-else :icon="['fal', 'times-circle']" />
								</template>
								<template v-slot:[`custom-slot-cell(contact_mail)`]="{ data }">
									<e-copybox classes="row" mail customtable :value="data.contact_mail"></e-copybox>                                             
								</template>
								<template v-slot:[`custom-slot-cell(phones_number)`]="{ data }">
									<span v-for="(phone_number, index) in data.phones_number.split('<br>')" :key="index">
										<e-copybox classes="row" phone customtable :value="phone_number"></e-copybox>                                             
									</span>
								</template>
							</CustomTable>
						</div>

						<ContactForm />
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script type="text/javascript">
import Contact from "@/mixins/Contact.js"
import Navigation from "@/mixins/Navigation.js"

export default {
	name: "ContactList",
	mixins: [Contact, Navigation],
	data () {
		return {
			table_busy: true,
			contacts: [],
			extern_slot_columns: [
				'contact_operateur',
				'phones_number',
				'contact_mail'
				],
			rawColumnNames: [],
			events_tab: {
				'TableAction::goToArchiveContact': this.archiveContact
			},
			config_table_hrefs: {
				'contact_firstname': {
					routeUniqueName: 'contactCoordonnees',
					routeName: 'contactCoordonnees',
					params: {
						contact_id: 'contact_id'
					}
				},
				'contact_lastname': {
					routeUniqueName: 'contactCoordonnees',
					routeName: 'contactCoordonnees',
					params: {
						contact_id: 'contact_id'
					}
				}
			},
		}
	},
	mounted () {
		this.init_component()
	},
	methods: {
		async init_component() {
			this.loadContacts()
		},
		async loadContacts() {
			this.table_busy = true
			this.contacts = await this.getAllContact()
			this.table_busy = false
		},
		async archiveContact(contacts) {
			const ids = contacts.map(c => c.contact_id)
			await this.archiveContacts(ids)
			this.loadContacts()
			this.$refs.table.refreshTable()
		}
	},
	computed: {
		filtredContacts: function() {
			let contactsFormated = []
			if(!this.contacts) {
				return []
			}

			const copy_temp = this.deppCloneObj(this.contacts)
			for(let i = 0; i < copy_temp.length; i++) {
				const temp = {...copy_temp[i], ...copy_temp[i].contact}
				temp.phones_number = this.getArrayObjProperty(temp.phones, "phone_combine").join("<br>")
				contactsFormated.push(temp);
			}

			return contactsFormated
		}
	},
	components: {
		HeaderTab: () => import('@/components/HeaderTab'),
		CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
		ContactForm : () => import('@/components/Contact/ContactForm')
	}
}
</script>
